import "./BlogPosts.scss";

import React, { useEffect, useState } from "react";

import BlogFilters from "./BlogFilters";
import PostItem from "./PostItem";
import { Header } from "../../components/Header/Header";

interface Props {
  posts: {
    categories: string[];
    title: string;
    abstract: string;
    imageSource: string;
    link: string;
  }[];
}

export function BlogPosts({ posts }: Props) {
  const [filtersList, setFiltersList] = useState<string[]>([]);
  const [activeFilters, setActiveFilters] = useState<string[]>([]);

  useEffect(() => {
    const filtersListFromPosts: string[] = [];
    posts.forEach((post) => {
      post.categories.forEach((category) => {
        if (!filtersListFromPosts.includes(category.toLowerCase())) {
          filtersListFromPosts.push(category.toLowerCase());
        }
      });
    });
    setFiltersList(filtersListFromPosts);
  }, [posts, activeFilters]);

  const activateFilter = (filter: string) => {
    const filterToActivate = filter.toLowerCase();

    if (!activeFilters.includes(filterToActivate)) {
      setActiveFilters((currentActiveFilters) => [
        ...currentActiveFilters,
        filterToActivate,
      ]);
    }
  };

  const removeFilter = (filter: string) => {
    const activeFiltersList = activeFilters.filter(
      (filterName) => filterName !== filter.toLowerCase()
    );
    setActiveFilters([...activeFiltersList]);
  };

  const clearFilters = () => {
    setActiveFilters([]);
  };

  return (
    <div className="blog-posts">
      <Header title="Blog" />
      {/* possibly to be restored in future */}
      {/* <BlogFilters
        filtersList={filtersList}
        activeFilters={activeFilters}
        activateFilter={activateFilter}
        removeFilter={removeFilter}
        clearFilters={clearFilters}
      /> */}
      <div className="blog-items">
        {activeFilters.length === 0
          ? posts.map((post, index) => {
              return <PostItem key={index} {...post} />;
            })
          : posts
              .filter((post) =>
                activeFilters.every((filter) =>
                  post.categories.includes(filter)
                )
              )
              .map((post, index) => {
                return <PostItem key={index} {...post} />;
              })}
      </div>
    </div>
  );
}
