import Button from "../widgets/Button/Button";
import { Link } from "gatsby";
import React from "react";

interface PostItemProps {
  categories: string[];
  title: string;
  abstract: string;
  imageSource: string;
  link: string;
}

function PostItem(props: PostItemProps) {
  const { categories, title, abstract, imageSource, link } = props;
  return (
    <div className="blog-item">
      <div className="blog-item__categories">
        {categories &&
          categories.map((category, index) => {
            return <div key={index}>{category}</div>;
          })}
      </div>
      <Link className="blog-item__row" to={`/blog/${link}`}>
        <div className="blog-item__column">
          <div className="blog-item__image">
            <img src={imageSource} alt="" />
          </div>
        </div>
        <div className="blog-item__column blog-item__column--right">
          <div className="blog-item__title">{title}</div>
          <div className="blog-item__abstract">{abstract}</div>
          <Button text="Read" customClass="right" />
        </div>
      </Link>
    </div>
  );
}

export default PostItem;
