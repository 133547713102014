import { BlogPosts } from "../components/Blog/BlogPosts";
import { ContinueReading } from "../components/ContinueReading/ContinueReading";
import React from "react";
import { graphql } from "gatsby";
import Seo from "../components/Seo/seo";

function BlogPage({ data }) {
  let posts = [];
  console.log(data.allMdx.edges);
  data.allMdx.edges.forEach((x) => {
    posts.push({
      categories: x.node.frontmatter.categories,
      title: x.node.frontmatter.title,
      abstract: x.node.frontmatter.abstract,
      imageSource: x.node.frontmatter.imageSource.childImageSharp.fluid.src,
      link: x.node.frontmatter.link,
      date: x.node.frontmatter.date,
    });
  });

  posts = posts.sort(function(a, b) {
    var aa = a.date
        .split("/")
        .reverse()
        .join(),
      bb = b.date
        .split("/")
        .reverse()
        .join();
    return aa > bb ? -1 : aa < bb ? 1 : 0;
  });

  return (
    <>
      <Seo title={"Blog"} />
      <div className="sections">
        <section>
          <BlogPosts posts={posts} />
        </section>
        <section>
          <ContinueReading nextSection={"Contact"} link="contact" />
        </section>
      </div>
    </>
  );
}

export default BlogPage;

export const pageQuery = graphql`
  query {
    allMdx(filter: { fileAbsolutePath: { regex: "/(blog)/" } }) {
      edges {
        node {
          id
          frontmatter {
            title
            abstract
            categories
            link
            date
            imageSource {
              childImageSharp {
                fluid(quality: 99) {
                  ...GatsbyImageSharpFluid_withWebp_noBase64
                }
              }
            }
          }
        }
      }
    }
  }
`;
